.navbar {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0);
  min-width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  z-index: 100;
}

.navbar .logo img {
  height: 80px;
}

.navbar .links ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style-type: none;
  gap: 1rem;
  position: relative;
}

.navbar .links ul li {
  min-width: 5rem;
  display: flex;
  justify-content: center;
}

.dropdown {
  position: relative;
  display: inline-block;
  min-width: 100%;
  text-align: center;
}

.dropdown a,
.dropdown p {
  color: white;
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.3rem;
  cursor: pointer;
}

.dropdown-content {
  display: none;
  position: absolute;
  width: 5rem;
  left: 0px;
  z-index: 10;
}

.dropdown-content a,
.dropdown-content p {
  color: white;
  padding-block: 0.5rem;
  text-decoration: none;
  display: block;
  font-weight: 600;
  font-size: 1rem;
}

.dropdown:hover .dropdown-content {
  display: block;
}
