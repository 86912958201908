.crew {
  min-height: 100vh;
  background-color: black;
}

.crew .content {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 65%;
  width: 65%;
  margin: auto;
  padding-top: 13rem;
  padding-bottom: 4rem;
  gap: 3rem;
}

.crew .content .title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: fit-content;
  gap: 1rem;
}

.crew .content .title h1 {
  font-size: 2.5rem;
  font-weight: 700;
  color: rgb(153, 125, 52);
  text-align: center;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

.crew .content img {
  width: 50%;
}

.prof_cards {
  min-width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
}

.prof_card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
}

.prof_card {
  min-width: 300px;
  height: 400px;
  perspective: 1000px; /* Enable 3D perspective for the flip effect */
}

.prof_card_inner {
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 0.8s;
}

.prof_card:hover .prof_card_inner {
  transform: rotateY(
    180deg
  ); /* Rotate the card on hover to reveal the backside */
}

.prof_card_front,
.prof_card_back {
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 1rem;
  backface-visibility: hidden; /* Hide the backface of the card */
}

.prof_card_front {
  background-color: #f4eee0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: Arial, sans-serif;
}

.prof_card_front h3 {
  font-size: 1.3rem;
  font-weight: 600;
  color: rgb(153, 125, 52);
  text-align: center;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

.prof_card_front span {
  font-size: 1.2rem;
  font-weight: 600;
  color: rgb(153, 125, 52);
  text-align: center;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

.prof_pic {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 60%;
}

.prof_card_front img {
  width: 220px !important;
  aspect-ratio: 15/16;
}

.prof_card_back {
  background-color: rgb(160, 147, 113);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 0.5rem;
  padding-block: 5rem;
  transform: rotateY(180deg); /* Initially hide the backside */
}

.prof_card_back h3 {
  font-size: 1.9rem;
  font-weight: 600;
  color: rgb(255, 250, 236);
  text-align: center;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

.prof_card_back span {
  font-size: 1.2rem;
  font-weight: 600;
  color: rgb(255, 250, 236);
  text-align: center;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

.prof_card_back p {
  font-size: 1rem;
  font-weight: 600;
  width: 80%;
  line-height: 22px;
  color: rgb(255, 250, 236);
  text-align: center;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

.bottomLink {
  padding-top: 2rem;
  min-width: 100%;
}

.bottomLink a {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-width: 100%;
  gap: 1rem;
  text-decoration: none;
  color: white;
}

.bottomLink img {
  width: 200px !important;
}

.bottomLink p {
  font-size: 1.2rem;
  font-weight: 500;
  letter-spacing: -1px;
  color: white !important;
}

.bottomLink .text {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.bottomLink .text p {
  font-size: 1.4rem;
  font-weight: 600;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: -1px;
  text-transform: uppercase;
  gap: 1rem;
  color: goldenrod;
  position: relative;
}

.bottomLink span {
  font-size: 2rem;
  font-weight: 600;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  position: absolute;
  right: -3rem;
  color: goldenrod;
  transition: all 0.3s ease-out;
}

.bottomLink span:hover {
  color: rgb(153, 125, 52);
}
