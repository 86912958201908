.backstory {
  background: url("/src/assets/about/backstory/background.webp");
  min-height: 100vh;
  background-size: cover;
  background-position: 50%;
}

.backstory .content {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 60%;
  width: 60%;
  margin: auto;
  padding-top: 10rem;
  padding-bottom: 4rem;
}

.backstory .content h3 {
  font-size: 3rem;
  letter-spacing: normal;
  color: rgb(70, 70, 70);
  font-weight: bold;
  text-align: center;
  text-transform: capitalize;
  margin-bottom: 2rem;
  font-family: "Courier New", Courier, monospace;
}

.backstory .content .inner_slide {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 100%;
  gap: 3rem;
}

.backstory .slide {
  position: relative;
  width: 100%;
}

.backstory .content .slide img {
  width: 100%;
}

.backstory .content .slide_text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: 60%;
}

.backstory .content .slide_text .firts_text {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.backstory .content .slide_text p,
span {
  font-size: 1rem;
  font-weight: 500;
  color: rgb(0, 0, 0);
  text-transform: capitalize;
  font-family: "Courier New", Courier, monospace;
}

.backstory .content .slide_text .middle_text {
  text-align: center;
  width: 60%;
}

.backstory .content .slide_text .final_text {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  min-width: 100%;
}

.backstory .slide_bottom {
  padding-top: 2rem;
  min-width: 100%;
}

.backstory .slide_bottom a {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-width: 100%;
  gap: 1rem;
  text-decoration: none;
  color: black;
}

.backstory .content .slide_bottom img {
  width: 170px;
}

.backstory .content .slide_bottom p {
  font-size: 1.2rem;
  font-weight: 500;
  letter-spacing: -1px;
}

.backstory .content .slide_bottom span {
  font-size: 2rem;
  font-weight: 500;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: -1px;
  text-transform: uppercase;
  gap: 1rem;
}

.swipper_iconsL {
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 10;
  font-size: 2.5rem;
  cursor: pointer;
  transition: all 0.2s ease-out;
}

.swipper_iconsR {
  position: absolute;
  top: 50%;
  right: 0;
  font-size: 2.5rem;
  cursor: pointer;
  transition: all 0.2s ease-out;
}

.swipper_iconsR:hover,
.swipper_iconsL:hover {
  color: goldenrod;
}
