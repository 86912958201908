.mainpage .card {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 300px;
  border-radius: 20px;
}

.mainpage .card .inner_card {
  display: flex;
  align-items: center;
  max-height: 300px;
  max-width: 300px;
  margin-bottom: 3rem;
  border-radius: 20px;
  background-color: rgb(236, 235, 235);
  cursor: pointer;
}

.mainpage .card .inner_card .bottle {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding-left: 0.5rem;
}

.mainpage .card .inner_card .bottle img {
  width: 90px;
  transition: all 0.2s ease-out;
}

.mainpage .card .inner_card .card_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
  justify-content: space-around;
  text-align: center;
  color: black;
}

.mainpage .card .inner_card .card_content p {
  margin-left: auto;
  margin-right: auto;
  letter-spacing: -1px;
  text-transform: uppercase;
  line-height: 20px;
  font-weight: 500;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

.mainpage .card .inner_card .card_content span {
  font-size: 0.8rem;
  font-weight: 600;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  text-transform: uppercase;
}

.mainpage .card .inner_card .card_content img {
  width: 130px;
  transition: all 0.8s ease-out;
}

.mainpage .card .bottom_content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 2rem;
}

.mainpage .card .bottom_content p {
  font-size: 2.5rem;
  font-weight: 400;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  text-transform: uppercase;
  padding-inline: 0.5rem;
}
.mainpage .card .bottom_content button {
  background-color: darkgoldenrod;
  text-decoration: none;
  text-transform: uppercase;
  color: white;
  font-size: 1rem;
  font-weight: 600;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  border-radius: 20px;
  letter-spacing: 2px;
  padding: 1.2rem 1.5rem;
  border: none;
  transition: all 0.3s ease-out;
  cursor: pointer;
}

.mainpage .card .bottom_content button:hover {
  background-color: goldenrod;
  scale: 1.05;
}

.mainpage .card:hover .bottle img {
  transform: scale(1.1);
}

.mainpage .card:hover .card_content img {
  transform: rotate3d(0, 1, 0, 360deg);
}
