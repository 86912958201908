.merch {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  background-color: black;
}

.merch .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  padding-bottom: 4rem;
  padding-top: 5rem;
}

.merch .content .title {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  width: fit-content;
  min-height: 30vh;
  margin-bottom: 2rem;
  background-image: url(/src/assets/cocktails/all/bg.png);
  background-position: center;
  background-size: 90% 90%;
  background-repeat: no-repeat;
}

.merch .content .title h2 {
  font-size: 3rem;
  font-weight: 400;
  text-transform: uppercase;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  letter-spacing: -1px;
  color: white;
}

.merch .content .title p {
  font-size: 1.2rem;
  font-weight: 100;
  color: #cca776;
  text-transform: uppercase;
  letter-spacing: 8px;
}

.merch .content .shop {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 5rem;
  margin-inline: 15px;
  max-width: 1140px;
  gap: 2rem;
}

.merch .content .shop .sellcard {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  width: 22rem;
  float: left;
}

.merch .content .shop .sellcard .sellCard_img {
  position: relative;
  min-height: 22rem;
}

.merch .content .shop .sellcard .sellCard_img img {
  width: 100%;
}

.merch .content .shop .sellcard .sellCard_img button {
  display: none;
  position: absolute;
  padding: 1rem 1.5rem;
  color: white;
  background-color: #cca776;
  border: none;
  border-radius: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 0.8rem;
  cursor: pointer;
  bottom: 1rem;
  left: 8rem;
  z-index: 10;
}

.merch .content .shop .sellcard .sellCard_img:hover .img2 {
  display: block;
  width: 100%;
}

.merch .content .shop .sellcard .sellCard_img:hover .img1 {
  display: none;
}

.merch .content .shop .sellcard .sellCard_img:hover button {
  display: block;
}

.merch .content .shop .sellcard .img2 {
  display: none;
}

.merch .content .shop .sellcard p {
  font-size: 1rem;
  font-weight: 400;
  text-transform: uppercase;
  text-align: center;
  padding-top: 1rem;
}

.merch .content .shop .sellcard span {
  font-size: 1.2rem;
  font-weight: 400;
  padding-top: 0.5rem;

  color: white;
  text-transform: uppercase;
}
