.slider-container {
  width: 100%;
  position: relative;
  overflow: hidden;
  min-height: 100%;
}

.slider {
  display: flex;
  transition: transform 0.3s ease;
}

.slide {
  flex: 0 0 100%;
  width: 100%;
  min-height: 100%;
  transition: transform 0.5s ease;
}

.slide-content {
  width: 100%;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #3498db;
  color: #fff;
  font-size: 24px;
  position: relative;
  gap: 1.5rem;
}

.slide-content .bottle {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 35%;
  min-width: 35%;
  min-height: 100%;
  margin-right: 1rem;
}

.slide-content img {
  width: 130px;
}

.slide-content .slide-content-container {
  display: flex;
  flex: 65%;
  flex-direction: column;
  min-width: 65%;
}

.slide-content .slide-content-container .slide-content-text {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;
  width: 70%;
}
.slide-content .slide-content-container .slide-content-text h4 {
  font-size: 1.5rem;
  font-weight: 400;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  text-transform: uppercase;
  letter-spacing: -1px;
}
.slide-content .slide-content-container .slide-content-text p {
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 1px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  text-transform: uppercase;
}

.slide-content .slide-content-container .slide-content-text img {
  width: 100%;
}

.slide-content .bottom_content {
  display: flex;
}

.bottom_content span {
  font-size: 1.2rem;
  font-weight: 100;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  text-transform: uppercase;
  padding-inline: 0.5rem;
}

.bottom_content a {
  text-decoration: none;
  font-size: 1.2rem;
  color: gold;
  text-transform: uppercase;
  font-weight: 100;
  padding-inline: 0.5rem;
  transition: all 0.3s ease-out;
}

.bottom_content a:hover {
  color: goldenrod;
  scale: 1.05;
}

.slider-navigation {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.slider-dot {
  width: 20px;
  height: 20px;
  border: 2px solid #fff;
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.slider-dot:hover {
  background-color: #ffffff61;
  transform: scale(1.1);
}

.slider-dot.active {
  background-color: white;
}
