.whisky {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  background-color: black;
}

.whisky .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  padding-bottom: 4rem;
  padding-top: 5rem;
}

.whisky .content .title {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  width: fit-content;
  min-height: 30vh;
  margin-bottom: 2rem;
  background-image: url(/src/assets/cocktails/all/bg.png);
  background-position: center;
  background-size: 90% 90%;
  background-repeat: no-repeat;
}

.whisky .content .title h2 {
  font-size: 3rem;
  font-weight: 400;
  text-transform: uppercase;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  letter-spacing: -1px;
  color: white;
}

.whisky .content .title p {
  font-size: 1.2rem;
  font-weight: 100;
  color: #cca776;
  text-transform: uppercase;
  letter-spacing: 8px;
}

.whisky .content .bottles {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  min-height: 70vh;
  gap: 15rem;
  padding-top: 4rem;
}

.whisky .content .bottomBottles {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  min-height: 70vh;
  gap: 2rem;
  padding-top: 8rem;
}

.whisky .content .whisky_card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 18rem;
}

.whisky .content .whisky_card .whisky_card_img {
  background-image: url(/src/assets/shop/whisky/whiteBG.webp);
  background-repeat: repeat;
  background-size: 100% 100%;
  min-width: 100%;
  height: 25rem;
  border-radius: 20px;
  transition: 0.3s;
  position: relative;
}

.whisky .content .whisky_card .whisky_card_img:hover {
  background-image: url(/src/assets/shop/whisky/goldBG.webp);
}

.whisky .content .whisky_card .whisky_card_img img {
  z-index: 40;
  height: 480px;
  align-self: center;
  vertical-align: middle;
  max-width: 100%;
  display: block;
  position: absolute;
  border-radius: 20px;
  left: 5rem;
  top: -2rem;
  transition: all 0.2s ease-out;
}

.whisky .content .whisky_card .whisky_card_img:hover img {
  transform: scale(1.1);
}

.whisky .content .whisky_card .whisky_card_info {
  padding-top: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  text-align: center;
}

.whisky .content .whisky_card .whisky_card_info p {
  font-size: 1.2rem;
  font-weight: 100;
  color: white;
  text-transform: uppercase;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  letter-spacing: -1px;
}

.whisky .content .whisky_card .whisky_card_info .subtitle,
.whisky .content .whisky_card .whisky_card_info .sub_subtitle {
  color: rgb(192, 142, 14) !important;
  font-size: 1rem !important;
  font-weight: bold !important;
}

.whisky .content .whisky_card .whisky_card_info .medal img {
  width: 100px;
  padding-bottom: 1rem;
}

.whisky .content .whisky_card .whisky_card_info .medal p {
  color: rgb(192, 142, 14) !important;
  font-size: 1rem !important;
  font-weight: bold !important;
}

.whisky .content .whisky_card .whisky_card_info .price {
  font-size: 2.5rem !important;
  padding-top: 1rem;
}

.whisky .content button {
  padding: 1rem 1.5rem;
  color: white;
  background-color: #cca776;
  border: none;
  border-radius: 1rem;
  font-weight: bold;
  font-size: 0.8rem;
  cursor: pointer;
}

.whisky .content button:hover {
  background-color: #bfa06b;
}

.whisky .content .sellBottles {
  display: flex;
  margin-block: 5rem;
  height: 33rem;
}

.whisky .content .sellBottles .leftContent {
  background-image: url(/src/assets/shop/whisky/whiteBG.webp);
  background-repeat: repeat;
  background-size: 100% 100%;
  width: 32.5rem;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.whisky .content .sellBottles .leftContent img {
  height: 550px;
  max-width: 100%;
  display: inline-block;
  vertical-align: middle;
}

.whisky .content .sellBottles .leftContent img:nth-child(2),
.whisky .content .sellBottles .leftContent img:nth-child(3) {
  height: 580px;
}

.whisky .content .sellBottles .rightContent {
  width: 28rem;
  border: 2px solid white;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 1rem;
  text-align: center;
  color: white;
}

.whisky .content .sellBottles .rightContent h3 {
  font-size: 1.5rem;
  font-weight: 600;
  text-transform: uppercase;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  color: white;
}

.whisky .content .sellBottles .rightContent p {
  width: 80%;
  font-weight: 100;
  color: white;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

.whisky .content .sellBottles .rightContent .list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding-top: 1rem;
}

.whisky .content .sellBottles .rightContent .list p {
  width: 100%;
}

.whisky .content .sellBottles .rightContent .list span {
  font-weight: 600;
  font-size: 0.8rem;
  color: #cca776;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

.prices {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding-top: 1rem;
}
.old_price {
  position: relative;
  font-size: 1rem;
  font-weight: bold;
  color: white;
}

.old_price::before {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  background-color: red;
  position: absolute;
  top: 50%;
  transform: rotate(-20deg);
  transform-origin: center;
}

.new_price {
  font-size: 2rem;
  font-weight: bold;
  color: white;
}
