.cocktailsAll {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  background-color: black;
}

.cocktailsAll .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  padding-bottom: 4rem;
  padding-top: 5rem;
}

.cocktailsAll .content .title {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  width: fit-content;
  min-height: 30vh;
  margin-bottom: 2rem;
  background-image: url(/src/assets/cocktails/all/bg.png);
  background-position: center;
  background-size: 90% 90%;
  background-repeat: no-repeat;
}

.cocktailsAll .content .title h2 {
  font-size: 3rem;
  font-weight: 400;
  text-transform: uppercase;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  letter-spacing: -1px;
  color: white;
}

.cocktailsAll .content .title p {
  font-size: 1.2rem;
  font-weight: 600;
  color: white;
}

.cocktailsAll .content .doors {
  display: flex;
  align-items: center;
  height: 70vh;
  justify-content: space-evenly;
  gap: 15rem;
  padding-top: 1.5rem;
}

.cocktailsAll .content .doors .door {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  min-width: 15rem;
  position: relative;
}

.cocktailsAll .content .doors .door .bottom_link {
  padding: 1rem 1.5rem;
  border: 1px solid rgb(162, 118, 56);
  border-radius: 2rem;
  background-color: #f5f5f500;
  color: rgb(162, 118, 56);
  font-size: 0.9rem;
  font-weight: 400;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.3s ease-out;
}

.cocktailsAll .content .doors .door .bottom_link:hover {
  background-color: rgb(162, 118, 56);
  color: rgb(219, 219, 219);
}

.cocktailsAll .content .doors .door img {
  display: block;
  max-width: 200px;
}

.cocktailsAll .content .doors .door .blue_door_open,
.cocktailsAll .content .doors .door .white_door_open {
  display: none;
}

.cocktailsAll .content .doors .door .blue_door:hover .blue_door_open,
.cocktailsAll .content .doors .door .white_door:hover .white_door_open {
  display: block;
}

.cocktailsAll .content .doors .door .blue_door:hover .blue_door_close,
.cocktailsAll .content .doors .door .white_door:hover .white_door_close {
  display: none;
}
