.join_list2 {
  background-image: url("/src/assets/shop/whisky/joinList.jpg");
  background-position: 50%;
  background-size: cover;
  min-width: 100%;
  height: 65vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.join_list2 .content {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 1.5rem;
}

.join_list2 .content h3 {
  font-size: 2rem;
  font-weight: 400;
  text-transform: uppercase;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  letter-spacing: -1px;
  line-height: 35px;
  color: white;
}

.join_list2 .content p {
  min-width: fit-content;
  max-width: 345px;
  color: #fff;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-size: 1.3rem;
  font-weight: 300;
  line-height: 25px;
  letter-spacing: 4px;
  display: block;
}

.join_list2 form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 100%;
  gap: 1rem;
}

.join_list2 .content input {
  width: 80%;
  padding-block: 1.3rem;
  padding-inline: 0.5rem;
  border-radius: 10px;
  border: none;
  font-size: 1rem;
}

.join_list2 .content input:focus {
  outline: none;
}

.join_list2 .content input:invalid {
  border: 1px solid red;
}

.join_list2 .content input:valid {
  border: 1px solid green;
}

.join_list2 .content button {
  text-decoration: none;
  color: goldenrod;
  font-size: 2.2rem;
  font-weight: 100;
  text-transform: uppercase;
  background-color: rgba(255, 0, 0, 0);
  border: none;
  cursor: pointer;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

.join_list2 .content button:hover {
  color: rgb(219, 207, 176);
}
