.press {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  background-color: black;
}

.press .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding-top: 8rem;
  padding-bottom: 4rem;
}

.press .content .title {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  background-color: black;
  width: 100%;
  height: 10rem;
  color: white;
  gap: 1rem;
  background-image: url(/src/assets/mainPage/wingsBg.jpg);
  background-size: 100%;
  background-position: 50% 10%;
  background-repeat: no-repeat;
  position: relative;
  z-index: 10;
}

.press .content .title span img {
  width: 120px;
}

.press .content .title h1 {
  font-size: 3rem;
  font-weight: 100;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: -2px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

.press .content .press_cards {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background-color: aliceblue;
  width: 100%;
  gap: 2rem;
  margin-top: 4rem;
  background-color: black;
}

.press .press_cards .list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  background-color: black;
}
.press .content .press_cards .press_card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  gap: 2rem;
  width: 29rem;
  height: 29rem;
  background-color: #f8f1e7;
  border-radius: 1rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  text-align: center;
}

.press .content .press_cards .press_card .main_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 17rem;
}

.press .content .press_cards .press_card .main_content img {
  width: 350px;
}

.press .content .press_cards .press_card p {
  font-size: 1rem;
  line-height: 23px;
  font-weight: 100;
  color: rgb(45, 45, 45);
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.press .content .press_cards .press_card .bottom_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 4rem;
  gap: 1rem;
  color: white;
  text-transform: uppercase;
  font-size: 1.5rem;
  font-weight: 100;
  letter-spacing: -1px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  border-radius: 0 0 1rem 1rem;
}

.press .content .press_cards .press_card .bottom_btn a {
  color: rgb(182, 133, 8);
  text-decoration: none;
  transition: all 0.3s ease-out;
}

.press .content .press_cards .press_card .bottom_btn a:hover {
  color: rgb(251, 186, 23);
}

@media (max-width: 1024px) {
  .list {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    background-color: black;
  }
}
